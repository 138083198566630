var __jsx = React.createElement;
import React, { useEffect, useState, useRef, useContext } from 'react';
import { useMapsLibrary } from '@vis.gl/react-google-maps';
import Heading from '../../atoms/Heading';
import Anchor from '../../atoms/Anchor';
import CircleSorryHappy from '../../../static/images/icons/CircleSorryHappy';
import UseMyLocation from '../../../static/images/icons/UseMyLocation';
import DeleteClose from '../../../static/images/icons/蘑菇tv/DeleteClose';
import { Context } from './MapStateProvider';
var FIND_UNKNOWN_LOCATION_ERROR = 'Sorry, we don鈥檛 recognize that location.';
var USE_MY_LOCATION_ERROR = 'Please allow the location permissions for your browser';
var autoCompleteOptions = {
  componentRestrictions: {
    country: ['us', 'ca']
  }
};

/**
 * Component to search for a location by:
 * - Autocomplete input
 * - Use my location button
 * @param {void} onPlaceSelect - Function to call when a place is selected
 */
function SearchLocation(_ref) {
  var onPlaceSelect = _ref.onPlaceSelect,
    onDeviceLocation = _ref.onDeviceLocation;
  var _useContext = useContext(Context),
    setSelectedLocationKey = _useContext.setSelectedLocationKey,
    handleReStartMap = _useContext.handleReStartMap,
    reStartMap = _useContext.reStartMap;
  var inputRef = useRef(null);
  var places = useMapsLibrary('places');
  var _useState = useState(''),
    useMyLocationText = _useState[0],
    setUseMyLocationText = _useState[1];
  var _useState2 = useState(false),
    isGeolocationActive = _useState2[0],
    setGeolocationAvailability = _useState2[1];
  useEffect(function () {
    if (!places || !inputRef.current) return;
    var placeSearchbox = new places.Autocomplete(inputRef.current);
    placeSearchbox.setFields(['address_components', 'geometry', 'name']);
    placeSearchbox.setOptions(autoCompleteOptions);
    placeSearchbox.addListener('place_changed', function () {
      setUseMyLocationText('');
      var place = placeSearchbox.getPlace();
      if (!place.geometry) {
        setUseMyLocationText(FIND_UNKNOWN_LOCATION_ERROR);
        return;
      }
      setSelectedLocationKey(null);
      onPlaceSelect(place);
    });
  }, [places]);
  useEffect(
  /**
   * When the map is reset externally, the input value is cleared
   * and the geolocation availability is set to false
   */
  function () {
    setGeolocationAvailability(false);
    inputRef.current.value = '';
  }, [reStartMap]);
  var getCurrentLocation = function getCurrentLocation() {
    inputRef.current.value = '';
    setUseMyLocationText('');
    if (!(navigator && navigator.geolocation)) {
      setUseMyLocationText(USE_MY_LOCATION_ERROR);
      return null;
    }
    navigator.geolocation.getCurrentPosition(handleDeviceLocation, handleDeviceLocationError);
  };
  var handleDeviceLocation = function handleDeviceLocation(position) {
    onDeviceLocation(position.coords.latitude, position.coords.longitude);
    setSelectedLocationKey(null);
    setGeolocationAvailability(true);
  };
  var handleDeviceLocationError = function handleDeviceLocationError() {
    setUseMyLocationText(USE_MY_LOCATION_ERROR);
  };
  var onReStartMap = function onReStartMap() {
    setGeolocationAvailability(false);
    setSelectedLocationKey(null);
    handleReStartMap();
  };
  var clearAutocomplete = function clearAutocomplete() {
    inputRef.current.value = '';
    inputRef.current.focus();
    handleReStartMap();
  };
  return __jsx("div", {
    className: "nva-maps-hhpets__location-filter location-filter"
  }, __jsx("div", {
    className: "nva-maps-hhpets__location-filter-title location-filter-title"
  }, __jsx("svg", {
    xmlns: "http://www.w3.org/2000/svg",
    height: "48",
    width: "48"
  }, __jsx("path", {
    d: "M24 23.5q1.45 0 2.475-1.025Q27.5 21.45 27.5 20q0-1.45-1.025-2.475Q25.45 16.5 24 16.5q-1.45 0-2.475 1.025Q20.5 18.55 20.5 20q0 1.45 1.025 2.475Q22.55 23.5 24 23.5Zm0 16.55q6.65-6.05 9.825-10.975Q37 24.15 37 20.4q0-5.9-3.775-9.65T24 7q-5.45 0-9.225 3.75Q11 14.5 11 20.4q0 3.75 3.25 8.675Q17.5 34 24 40.05ZM24 44q-8.05-6.85-12.025-12.725Q8 25.4 8 20.4q0-7.5 4.825-11.95Q17.65 4 24 4q6.35 0 11.175 4.45Q40 12.9 40 20.4q0 5-3.975 10.875T24 44Zm0-23.6Z"
  })), __jsx(Heading, {
    HeadingType: "h3",
    className: "nva-maps-hhpets__location-filter-title caption h3-static-size"
  }, "Find your location")), __jsx("div", {
    className: "nva-maps-hhpets__location-filter-helper-text helpText"
  }, "Enter a state or town or ZIP in the USA or Canada"), __jsx("div", {
    className: "nva-maps-hhpets__autocomplete autoComplete"
  }, __jsx("svg", {
    xmlns: "http://www.w3.org/2000/svg",
    height: "24",
    width: "24"
  }, __jsx("path", {
    d: "m19.6 21-6.3-6.3q-.75.6-1.725.95Q10.6 16 9.5 16q-2.725 0-4.612-1.887Q3 12.225 3 9.5q0-2.725 1.888-4.613Q6.775 3 9.5 3t4.613 1.887Q16 6.775 16 9.5q0 1.1-.35 2.075-.35.975-.95 1.725l6.3 6.3ZM9.5 14q1.875 0 3.188-1.312Q14 11.375 14 9.5q0-1.875-1.312-3.188Q11.375 5 9.5 5 7.625 5 6.312 6.312 5 7.625 5 9.5q0 1.875 1.312 3.188Q7.625 14 9.5 14Z"
  })), __jsx("div", {
    className: "nva-maps-hhpets__autocomplete-input-wrapper"
  }, __jsx("input", {
    ref: inputRef,
    className: "nva-maps-hhpets__autocomplete-input pac-target-input",
    placeholder: "City/State/ZIP"
  })), __jsx("button", {
    "aria-label": "Clear search",
    className: "nva-maps-hhpets__clear-autocomplete",
    onClick: clearAutocomplete,
    tabIndex: 0 // Safari focus fix
  }, __jsx(DeleteClose, null))), useMyLocationText && __jsx("div", {
    className: "nva-maps-hhpets__alert div-alert"
  }, __jsx("span", {
    className: "icon"
  }, __jsx(CircleSorryHappy, {
    className: "circle-sorry"
  })), __jsx("span", null, useMyLocationText)), __jsx("div", {
    className: "nva-maps-hhpets__use-my-location-wrapper use-my-location-container",
    role: "none"
  }, __jsx(Anchor, {
    className: "nva-maps-hhpets__use-my-location use-my-location ".concat(isGeolocationActive ? 'active is-active' : ''),
    tabIndex: "0",
    onClick: function onClick(e) {
      e.preventDefault();
      getCurrentLocation();
    },
    to: "",
    noLink: true
  }, __jsx("div", {
    className: "nva-maps-hhpets__use-my-location-icon use-my-location-icon"
  }, __jsx("span", {
    className: "icon"
  }, __jsx(UseMyLocation, {
    className: "circle-search"
  }))), __jsx("span", null, "USE MY LOCATION")), isGeolocationActive && __jsx(Anchor, {
    className: "nva-maps-hhpets__clear-my-location clear-my-location",
    tabIndex: "0",
    onClick: function onClick(e) {
      e.preventDefault();
      onReStartMap();
    },
    to: "",
    disabled: isGeolocationActive
  }, "Clear")));
}
export default SearchLocation;